import {createGlobalStyle} from 'styled-components';

const MarkdownPagesStyle = createGlobalStyle`

    body{
        font-family: 'DM Sans', sans-serif;
        font-weight:400;
        overflow:hidden;
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
        color:#424345;
        margin-bottom:20px;
    }


    a{
        font-family: 'DM Sans', sans-serif;
        font-weight:500;
        font-size:16px;
    }

    img{
        margin-bottom:0px;
    }
    h1{
        font-size:36px;
        line-height:42px;
    }
    h2{
        font-size:32px;
        line-height:38px;
    }
    h3{
        font-size:30px;
        line-height:36px;
    }
    h4{
        font-size:26px;
        line-height:32px;
    }
    h5{
        font-size:24px;
        line-height:30px;
    }
    h6{
        font-size:18px;
        line-height:24px;
    }
    p{
        font-family: 'DM Sans', sans-serif;
        font-weight:400;
        font-size:14px;
        line-height:25px;
        color:#565759;
        margin-bottom:20px;
    }

    li {
        font-family: 'DM Sans',sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        color: #565759;
        margin-bottom: 20px;
    }

`;

export default MarkdownPagesStyle;
