import React, { useState } from "react";
import HeaderSticky from '../containers/CustomContainers/HeaderSticky';
import FooterCustom from '../containers/CustomContainers/Footer';
import MarkdownPagesStyle from '../containers/CustomContainers/CustomStyle/MarkdownPagesStyle';
import "../components/layout.css";
import Seo from "../components/seo"
import Fonts from "../containers/CustomContainers/Common/fonts"
import {  graphql } from 'gatsby';


const PowerBIRobotsTerms = ({data}) => {
    const post = data.markdownRemark;
    const powerBiRobotsTermsJson = data.powerBiRobotsTermsJson;
    const [expanded, setExpanded] = useState(false);


    return (
   <div>
       <Fonts />
        <MarkdownPagesStyle />
        <HeaderSticky data={powerBiRobotsTermsJson.Header} expanded={expanded}  expandToggle={(newValue) => setExpanded(newValue)} />

        <main onClick={() => setExpanded(false) }>
        <div class="container" style={{marginTop: "100px", marginBottom: "100px"}}>
            <Seo 
                title={powerBiRobotsTermsJson.SEO.Title}
                description={powerBiRobotsTermsJson.SEO.Description}
                favIconPath={powerBiRobotsTermsJson.SEO.FavIconPath}
                previewImage={powerBiRobotsTermsJson.SEO.PreviewImage}
                keywords={powerBiRobotsTermsJson.SEO.Keywords}

            />

            <div dangerouslySetInnerHTML={{ __html: post.html }} />

            </div>

            
        </main>

        <FooterCustom data={powerBiRobotsTermsJson.Footer}/>
   </div>
   )

}

export const pageQuery  = graphql`
query markdownFileRobotsTerms {
  markdownRemark(frontmatter: { title: { eq: "PowerBIRobotsTerms" } }) {
    html
    frontmatter {
      path
      title
      author
      date
    }
  }
  powerBiRobotsTermsJson {
        SEO {
            Title
            Description
            FavIconPath
            PreviewImage
            Keywords
        }
        Header{
            BrandLogo
            BrandLogoSticky
            Items
            MenuItems{
                Menu
                Href
                LocalLink
            }
        }
        Footer {
            FooterLogo
            Items
            FooterCol2{
                FooterTitle
                FooterUl{
                    FooterMenu
                    Href
                    LocalLink
                }
            }
            FooterCol3{
                FooterTitle
                FooterUl{
                    FooterMenu
                    Href
                }
            }
            FooterCol4{
                FooterTitle
                FooterAddWrapper{
                    FooterAddImg
                    FooterAddText
                }
            }
            BottomFooterPara
            BottomFooterName
            BottomFooterLink
            SocialLinks{
                FacebookLink
                TwitterLink
                LinkedinLink
                InstagramLink
            }
        }
    }
}
`;

export default PowerBIRobotsTerms;
